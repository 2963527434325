import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from './environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { LoadingInterceptor } from './app/utils/loading.interceptor';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { RolesService } from './app/utils/roles.service';
import { MessageService } from './app/utils/message.service';
import { CookieService } from 'ngx-cookie-service';
import { ErrorInterceptorService } from './app/api/services/interceptors/error-interceptor.service';
import { TokenInterceptorService } from './app/api/services/interceptors/token-interceptor.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import * as Sentry from '@sentry/angular';
import { provideRouter, Router } from '@angular/router';
import AppRoutes from './app/app.routes';
import { MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { NoopScrollStrategy, Overlay } from '@angular/cdk/overlay';

window.Smart.License = '446FC96B-6972-4CF6-9595-6174362F10FD';

if (environment.sentry.dsn) {
  Sentry.init({
    environment: environment.envName,
    dsn: environment.sentry.dsn,
    release: environment.sentry.release,
    ignoreErrors: [],
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/test-stammdaten.sommer-gossmann\.com\/api/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        realm: window.location.hostname.indexOf('sommer-gossmann.com') !== -1 ? environment.SSO_REALM_SUG : environment.SSO_REALM_BASE,
        url: window.location.hostname.indexOf('sommer-gossmann.com') !== -1 ? environment.LOGIN_URL_SUG : environment.LOGIN_URL_BASE,
        clientId: window.location.hostname.indexOf('sommer-gossmann.com') !== -1 ? environment.SSO_CLIENT_ID_SUG : environment.SSO_CLIENT_ID_BASE,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets']
    });
}

export function scrollFactory(overlay: Overlay): () => NoopScrollStrategy {
  return () => overlay.scrollStrategies.noop();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(AppRoutes),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      KeycloakAngularModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: true,
        // enabled: environment.envName === 'production',
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      }),
      HttpCacheInterceptorModule.forRoot()
    ),
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    RolesService,
    MessageService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000, verticalPosition: 'top' }},
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    ...(environment.sentry.dsn ? [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler(),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ] : []),
  ],
}).catch((error) => console.error('Card Admin :: Initialization error :: ', error));
