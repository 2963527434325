import { Component, OnInit } from '@angular/core';
import { CompanySelectorService } from './shared/services/company-selector.service';
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { SwCacheService } from './utils/sw-cache.service';
import { Router, RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompanyService} from '@ems/api';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [NgIf, RouterOutlet]
})
export class AppComponent implements OnInit {
  shouldRedirectToHome = false;
  loading = true;

  constructor(
    private companyService: CompanyService,
    private companySelectorService: CompanySelectorService,
    private keycloak: KeycloakService,
    private cookieService: CookieService,
    private swCacheService: SwCacheService,
    private router: Router,
    private snack: MatSnackBar
  ) {}

  async ngOnInit() {
    this.snack.open('Bitte warten... Seite wird initialisiert.', undefined, { panelClass: 'snack-waiting', duration: 50000 })
    const keycloakAuth = this.keycloak.getKeycloakInstance();
    const lastSubject = this.cookieService.get('lastSubject');

    // reset SW cache in case of a new account logging in - do this on login, because logout is not reliable
    // currently this only works on page reload, because on explicit logout all cookieService.deleteAll is called and lastSubject is lost
    if (keycloakAuth.authenticated && keycloakAuth.subject && lastSubject !== keycloakAuth.subject) {
      // keycloak login can contain a redirect url; in case the user changes we want to start at home
      this.shouldRedirectToHome = true;
      this.cookieService.deleteAll();
      this.cookieService.set('lastSubject', keycloakAuth.subject);
      await this.swCacheService.clearCacheStorage();
    }

    keycloakAuth.onTokenExpired = () => {
      keycloakAuth.updateToken(50);
    }
    // load companies first, then enable pages
    this.companyService.getAllCompanies('customer', 1).subscribe((items: any[]) => {
      this.loading = false;
      this.snack.dismiss();
      this.companySelectorService.setCompanies(items);
      if (this.shouldRedirectToHome) {
        this.router.navigate(['/home']);
      }
    });
  }
}
