import { Component, OnInit } from '@angular/core';
import { NgClass, UpperCasePipe } from '@angular/common';

import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { KeycloakProfile } from 'keycloak-js';

import packages from '../../../../../package.json';
import { environment } from '../../../../environments/environment';
import {RolesService} from '../../../utils/roles.service';

@Component({
  standalone: true,
  selector: 'app-appversion',
  imports: [NgClass, UpperCasePipe],
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.css']
})
export class AppVersionComponent implements OnInit {
  userName = '';
  userRole = '';
  roles: any[] = [{
    'admin': 'Admin-Nutzer',
    'companies': 'Firmen-Nutzer',
    'branches': 'Filial-Nutzer'
  }];

  version = packages.version;
  buildDate = packages.builddate;
  apiChangelog = environment.API_CHANGELOG;
  production = environment.production;
  envName = environment.envName;

  constructor(
    private readonly keycloak: KeycloakService,
    private cookieService: CookieService,
    private rolesService: RolesService
  ) {}

  ngOnInit(): void {
    this.keycloak.loadUserProfile().then((profile: KeycloakProfile) => {
      this.userName = profile.firstName + ' ' + profile.lastName;

      if(this.cookieService.check('group')) {
        this.userRole = ' (' + this.roles[0][this.cookieService.get('group')] +  ' ' + JSON.stringify(this.rolesService.getUserAccess()).toUpperCase() +')';
      }
    });
  }
}
