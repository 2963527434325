import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';

interface HttpError {
  status: number;
  title: string;
  description: string;
  action: string;
  errorResponse?: HttpErrorResponse
}

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  private readonly httpErrors = new Map<number, HttpError>([
    [
      0,
      {
        status: 0,
        title: 'Unbekannter Fehler!',
        description: 'Es ist ein unbekannter Fehler aufgetreten!',
        action: 'Bitte wende dich an den Systemadministrator.'
      }
    ],
    [
      403,
      {
        status: 403,
        title: 'Zugriff verweigert!',
        description: 'Du hast für diese Anfrage keine Berechtigung!',
        action: 'Bitte wende dich an den Systemadministrator.'
      }
    ],
    [
      404,
      {
        status: 404,
        title: 'Seite nicht gefunden!',
        description: 'Die gewünschte Anfrage konnte nicht gefunden werden!',
        action: 'Bitte wende dich an den Systemadministrator.'
      }
    ],
    [
      422,
      {
        status: 422,
        title: 'API-Anfrage nicht verarbeitet!',
        description: 'Die gewünschte API-Anfrage konnte nicht wie erwartet verarbeitet werden!',
        action: 'Bitte wende dich an den Systemadministrator.'
      }
    ],
    [
      500,
      {
        status: 500,
        title: 'Serverfehler!',
        description: 'Es ist ein Serverfehler aufgetreten!',
        action: 'Bitte wende dich an den Systemadministrator.'
      }
    ],
    [
      504,
      {
        status: 504,
        title: 'Gateway timeout!',
        description: 'Der Server ist im Moment nicht erreichbar!',
        action: 'Bitte versuche es später wieder.'
      }
    ],
  ]);

  private errorList: HttpError[] = [];

  constructor(private dialog: MatDialog) { }

  add(errorResponse: HttpErrorResponse) {
    if (!this.dialog.openDialogs.length) {
      this.dialog
        .open(ErrorDialogComponent, { width: '800px', data: { errorList: this.errorList } })
        .afterClosed()
        .subscribe(() => this.clear());
    }

    const error = {
      ...(this.httpErrors.get(errorResponse.status) || this.httpErrors.get(0)!),
      errorResponse,
    }

    this.errorList.push(error);
  }

  clear() {
    this.errorList = [];
  }
}
