export const sortByField = (field: string, reverse = false) => {
  return (a: any, b: any) => {
    const nameA = a[field].toUpperCase();
    const nameB = b[field].toUpperCase();

    if (nameA < nameB) {
      return reverse ? 1 : -1;
    }
    if (nameA > nameB) {
      return reverse ? -1 : 1;
    }

    return 0;
  }
}
