import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private keycloakService = inject(KeycloakService);
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return fromPromise(this.keycloakService.getToken()).pipe(
      switchMap((token: string) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        return next.handle(request);
      })
    )
  }
}
