import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseHttpService } from '@ems/api';
import { CookieService } from 'ngx-cookie-service';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({
  providedIn: 'root'
})
export class DatasourceDistributionAreaService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getAllList(): Observable<any[]> {
    const queryParams = {
      'loader': false,
    };
    return this.getAll('datasource-distribution-areas', queryParams);
  }
}
