import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;
  private translate: any = {
    'address': 'Adressdaten',
    'badges': 'Siegel',
    'badge': 'Siegel',
    'billing-details': 'Rechnungsdaten',
    'company': 'Firmendaten',
    'companies': 'Firmen',
    'contacts': 'Kontakte',
    'contact': 'Kontakt',
    'contact-medium': 'Beziehung Medium-Kontakt',
    'countries': 'Länder',
    'consents': 'Einwilligungen',
    'consent': 'Einwilligung',
    'company-medium': 'Beziehung Firma-Medium',
    'sections': 'Sparten',
    'section': 'Sparte',
    'media-categories': 'Medienkategorien',
    'medium-ratings': 'Medienbewertungen',
    'datasource-distribution-areas': 'Datenquelle der Verbreitungsgebiete',
    'release-schedules': 'Erscheinrhythmus',
    'media': 'Medien',
    'medium': 'Medium',
    'branches': 'Filialen',
    'branch': 'Filiale',
    'publication-days': 'Erscheintage',
    'products': 'Produkte',
    'product-prices': 'Produktpreise',
    'price-term': 'Konditionen',
    'responsibilities': 'Zuständigkeit'
  }

  constructor(
    private loadingService: LoaderService
  ) {}

  getQueryParams(url: any) {
    let paramsBlock = url.split("?")[1];
    return paramsBlock?.split('&').reduce((p: any, c: any) => {
      let components = c.split('=');
      p[components[0]] = components[1]
      return p;
    }, new Map<string, string>());
  }

  helper(request: string) {
    const req: any = request.split('?')[0].split('/');
    const ret: any = typeof this.translate[req[req.length - 1]] === 'string' ? this.translate[req[req.length - 1]]: this.translate[req[req.length - 2]];
    return ret ? ret: 'Daten';
  }

  loader(request: any) {
    const loader = this.getQueryParams(request.url)?.loader || request.params;
    let result = true;

    if(typeof loader === 'string') {
      result = JSON.parse(loader);
    } else {
      request?.params['map'].forEach( (param: any, k: any) => {
        if(k === 'loader') {
          result = JSON.parse(param[0]);
        }
      })
    }
    //console.log(request.url, this.helper(request.url), result, request.url.split('?')[0].split('/'));
    return result;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.totalRequests++;
    // this.loadingService.setLoading(this.loader(request));
    this.loadingService.setLoading(false);
    this.loadingService.target = this.helper(request.url) + ' laden... Bitte warten.';
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loadingService.setLoading(false);
          this.loadingService.target = '';
        }
      })
    );
  }
}
