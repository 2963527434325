import { AdvertisingType, BaseHttpService } from '@ems/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class AdvertisingTypeService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getAdvertisingTypes(advertisingCategoryId?: string): Observable<AdvertisingType[]> {
    let queryParams = {};
    if (advertisingCategoryId) {
      queryParams = {
        ...queryParams,
        'filter[advertising_category_id][eq]': advertisingCategoryId
      }
    }

    return this.getAll('advertising-types', queryParams);
  }
}
