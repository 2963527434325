import { FrameComponent } from './core/components/frame/frame.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { Routes } from '@angular/router';

export default [
  {
    path: '',
    component: FrameComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'filialen',
        loadChildren: async () => (await import('./features/branch/branch.routes')).branchRoutes
      },
      {
        path: 'home',
        loadChildren: async () => (await import('./features/home/home.routes')).homeRoutes
      },
      {
        path: 'firmen',
        loadChildren: async () => (await import('./features/company/company.routes')).companyRoutes
      },
      {
        path: 'medien',
        loadChildren: async () => (await import('./features/media/media.routes')).mediaRoutes
      },
      {
        path: 'produkte',
        loadChildren: async () => (await import('./features/product/product.routes')).productRoutes
      },
      {
        path: 'konditionsverwaltung',
        loadChildren: async () => (await import('./features/condition/condition.routes')).conditionRoutes
      },
      {
        path: 'ansprechpartner',
        loadChildren: async () => (await import('./features/contact-person/contact-person.routes')).contactPersonRoutes
      },
      {
        path: 'faq',
        loadChildren: async () => (await import('./features/meta-nav/meta-nav.routes')).metaNavRoutes
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: '/',
      }
    ]
  },
] as Routes;
