import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseHttpService } from '@ems/api';
import { CookieService } from 'ngx-cookie-service';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({
  providedIn: 'root'
})
export class SectionService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  createSection(data: any): Observable<any> {
    return this.post('sections', data);
  }

  getAllList(companyId?: number, loader = false): Observable<any[]> {
    const queryParams = {
      'loader': loader,
      ...(companyId ? { 'filter[company_id][eq]': companyId } : {})
    };

    return this.getAll('sections', queryParams);
  }
}
