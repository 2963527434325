import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationToggleService {
  _open$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  open$ = this._open$.asObservable().pipe(distinctUntilChanged());

  toggle() {
    this._open$.next(!this._open$.getValue());
  }

  open() {
    this._open$.next(true);
  }

  close() {
    this._open$.next(false);
  }
}
