import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SwCacheService {
  // https://github.com/angular/angular/issues/24008
  clearCacheStorage(): Promise<Promise<boolean>[][] | null> {
    if (!window.caches) {
      return Promise.resolve(null);
    }
    return caches.keys().then((cacheKeys: string[]) =>
      Promise.all(
        cacheKeys.map((cacheKey: string) => {
          const ngswRegex = /^(ngsw).*/;
          if (ngswRegex.test(cacheKey)) {
            return caches
              .open(cacheKey)
              .then((cache: any) => cache.keys().then((requests: any) => requests.map((req: any) => cache.delete(req))));
          } else {
            return null;
          }
        })
      )
    );
  }
}
