import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { ErrorDialogService } from '../../../shared/services/error-dialog.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  private keycloakService = inject(KeycloakService);
  private errorDialogService = inject(ErrorDialogService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              this.keycloakService.logout();
              return throwError(error);
            default:
              this.errorDialogService.add(error)
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
