import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ApiUrlService {
  baseUrl = '';

  constructor() {
    const host = window.location.hostname.indexOf('sommer-gossmann.com') !== -1 ? environment.API_URL_SUG : environment.API_URL_BASE;
    this.baseUrl = host + environment.API_ROOT + environment.API_VERSION;
  }
}
