import { BaseHttpService, CalculationParameter } from '@ems/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { HttpCacheManager } from '@ngneat/cashew';
import { Observable } from 'rxjs';

const expandParams = {
  'expand[0]': 'companyPriceManagements.company',
  'expand[1]': 'companyPriceManagements.section',
  'expand[2]': 'updatedBy',
};

@Injectable({providedIn: 'root'})
export class CalculationParameterService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getList(priceManagementId: number): Observable<CalculationParameter[]> {
    const queryParams = {
      ...expandParams,
      ...(priceManagementId ? {'filter[price_management_id][eq]': priceManagementId} : {}),
    };

    return this.getAll('calculation-parameters', queryParams);
  }

  create(data: any): Observable<CalculationParameter> {
    this.invalidateCache();
    return this.post('calculation-parameters', data);
  }

  update(id: number, data: any): Observable<CalculationParameter> {
    this.invalidateCache();
    return this.post(`calculation-parameter/${id}`, data);
  }

  remove(id: number) {
    this.invalidateCache();
    return this.delete(`calculation-parameter/${id}`);
  }
}
