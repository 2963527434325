import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(
    protected router: Router,
    private keycloak: KeycloakService,
  ) {}

  canActivate(): boolean {
    if (this.keycloak.isLoggedIn()) {
      return true;
    } else {
      // cause redirect to login page
      this.keycloak.login({
        scope: 'offline_access'
      });
      return false;
    }
  }
}
