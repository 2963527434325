import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpCacheManager } from '@ngneat/cashew';
import { BaseHttpService } from './base-http.service';
import { WeightSetCreate, PriceManagementWeightSet } from '../interfaces/price-management-weight-set.interface';
import { ApiResponse } from '../interfaces/api-response.interface';

const expandParams = {
  'expand[0]': 'companyPriceManagements.company',
  'expand[1]': 'companyPriceManagements.section',
  'expand[2]': 'additionalWeightSurcharges',
  'expand[3]': 'weightRanges',
  'expand[4]': 'updatedBy'
};

@Injectable({providedIn: 'root'})
export class PriceManagementWeightSetService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }


  getDetail(priceManagementWeightSetId: number): Observable<ApiResponse<PriceManagementWeightSet>> {
    const queryParams = {
      ...expandParams
    };

    return this.get('price-management-weight-sets/' + priceManagementWeightSetId, queryParams);
  }

  create(payload: WeightSetCreate): Observable<PriceManagementWeightSet> {
    this.invalidateCache();
    return this.post('price-management-weight-sets', payload as any);
  }

  update(id: string, payload: WeightSetCreate): Observable<PriceManagementWeightSet> {
    this.invalidateCache();
    return this.post(`price-management-weight-sets/${id}`, payload as any);
  }

  remove(id: string): Observable<void>{
    this.invalidateCache();
    return this.delete(`price-management-weight-sets/${id}`);
  }

  getList(priceManagementId: number): Observable<PriceManagementWeightSet[]> {
    const queryParams = {
      ...expandParams,
      ...(priceManagementId ? { 'filter[price_management_id][eq]': priceManagementId } : {}),
    };

    return this.getAll('price-management-weight-sets', queryParams);
  }

}
