import { AdvertisingCategory, ApiResponse, BaseHttpService } from '@ems/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class AdvertisingCategoryService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager
  ) {
    super(http, cookieService, manager);
  }
  getAdvertisingCategories(): Observable<ApiResponse<AdvertisingCategory[]>> {
    const params = {
      'expand[]': 'advertisingTypes'
    };
    const url = 'advertising-categories';
    return this.get(url, params);
  }
}
